<template>
  <div class="askConsentOverlay">
    <div :class="type" :style="`padding: ${contentPadding}px`">
      <h1>{{ $t('consent.overlay.title') }}</h1>
      <p>{{ description }}</p>
      <button class="btn btn-primary mr-4 mt-4" @click="consentContent">{{ $t('consent.overlay.confirm') }}</button>
      <button class="btn btn-secondary mt-4" @click="goToPrivacyPolicy">{{ $t('consent.overlay.policy') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String
    },
    contentPadding: {
      type: Number,
      default: 200
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('settings', ['featuresWithConsent']),
    readableType() {
      switch (this.type) {
        case 'googleMaps':
          return 'Google.';
        case 'youtube':
          return 'Youtube.';
        case 'vimeo':
          return 'Vimeo.';
        case 'twentyThree':
          return 'Twenty Three.';
        case 'videoTool':
          return 'Video Tool';
        case 'dreamBroker':
          return 'Dream Broker';
      }
    },
    description() {
      return this.$t('consent.overlay.description', { contentType: this.readableType });
    }
  },
  methods: {
    goToPrivacyPolicy() {
      window.open('/privacy-policy', '_blank');
    },
    consentContent() {
      const newConsent = { ...this.featuresWithConsent };
      newConsent[this.type] = true;
      this.$store.dispatch('settings/setFeaturesWithConsent', { user: this.user, content: newConsent });
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
div h1,
div p {
  color: #f8f9fa !important;
}

div.askConsentOverlay {
  display: grid;
  justify-items: center;
}

div {
  text-align: center;
}

div.googleMaps,
div.youtube,
div.vimeo,
div.twentyThree,
div.videoTool,
div.dreamBroker {
  background-size: cover;
  width: 100%;
}

div.googleMaps {
  background-image: url('consentOverlay.jpg');
}

div.youtube,
div.vimeo,
div.twentyThree,
div.videoTool,
div.dreamBroker {
  background-image: url('consentOverlay.jpg');
}
</style>
